import React, { useState, useEffect } from "react";

import Layout from "../layouts/LayoutDefault";
import Seo from "../components/Seo";
import HomeIntro from "../components/testimonials/Intro";

import "../components/testimonials/index.scss";
import Testimonials from "../components/common/Testimonials";
import TrackedLink from "../components/Analytics/TrackedLink";

const PgT = () => {
  const [isShowYoutubeModal, setIsShowYoutubeModal] = useState(false);
  const [urlYoutubeModal, setUrlYoutubeModal] = useState("");

  const closeYoutubeModal = () => setUrlYoutubeModal("");

  useEffect(() => {
    setIsShowYoutubeModal(!!urlYoutubeModal);
  }, [urlYoutubeModal]);

  return (
    <Layout
      bgHeader="#fbfbfb"
      isShowYoutubeModal={isShowYoutubeModal}
      closeYoutubeModal={closeYoutubeModal}
      urlYoutubeModal={urlYoutubeModal}
    >
      <Seo
        title="Testimonials - Amazon Seller Basics | AMZScout"
        description="Free workshops & webinars about Amazon selling basics hosted by YouTube bloggers and pro sellers. Hurry up to book the last seat."
        page="testimonials"
        manifest="browserconfig.xml"
      />
      <HomeIntro />
      <Testimonials setUrlYoutubeModal={setUrlYoutubeModal} isPage />
      <div className="PgT-btn">
        <TrackedLink
          category="TestimonialsLP"
          action="TryForFreePricing"
          class="button"
          target
          path="https://chrome.google.com/webstore/detail/amzscout-pro/njopapoodmifmcogpingplfphojnfeea"
        >
          Try PRO Extension for free
        </TrackedLink>
      </div>
    </Layout>
  );
};

export default PgT;
